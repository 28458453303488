<template>
  <div class="flex flex-col">
    <div class="flex-1 lg:mt-6" v-if="data">
      <div
        class="container flex flex-col pl-5 pr-4 mx-auto mt-4 mb-4 lg:flex-row"
      >
        <div class="lg:hidden">
          <!-- <p class="text-primary font-lg">Best Value for the money</p> -->
          <p v-html="data.Name"></p>
          <p v-html="data.Price"></p>
        </div>
        <!-- </div>
        <div>-->
        <div class="lg:w-1/2 lg:flex lg:flex-row-reverse">
          <div
            class="flex w-full p-2 mb-5 bg-shade lg:mx-8 lg:mb-0"
            style="min-height: 500px"
          >
            <div class="my-auto">
              <img
                src="../assets/GrayDoubleArrowLeft.svg"
                class="px-3 cursor-pointer arrow-left"
                v-if="multiplePics"
                @click="turnLeft"
              />
            </div>
            <div class="flex items-center w-full">
              <img
                :src="photo"
                class="flex-1 block w-full"
                style="max-height: 480px"
              />
            </div>
            <div class="my-auto">
              <img
                src="../assets/GrayDoubleArrowRight.svg"
                class="px-3 cursor-pointer arrow-right"
                v-if="multiplePics"
                @click="turnRight"
              />
            </div>
          </div>

          <div class="flex flex-row lg:flex-col">
            <div
              class="flex justify-center w-1/3 p-2 ml-10 border cursor-pointer bg-shade lg:w-full lg:ml-0"
              :class="thumb == 0 ? 'border-primary' : 'border-white'"
              @click="thumb = 0"
            >
              <div class="flex items-center w-16 h-16 mx-auto">
                <img
                  :src="data.Photo_1"
                  style="max-height: 50px"
                  class="mx-auto"
                />
              </div>
            </div>
            <div
              class="flex justify-center w-1/3 p-2 mx-4 border cursor-pointer bg-shade lg:w-full lg:mx-0 lg:my-4"
              :class="thumb == 1 ? 'border-primary' : 'border-white'"
              @click="thumb = 1"
              v-if="data.Photo_2.length"
            >
              <div class="flex items-center w-16 h-16">
                <img
                  :src="data.Photo_2"
                  style="max-height: 50px"
                  class="mx-auto"
                />
              </div>
            </div>
            <div
              class="flex justify-center w-1/3 p-2 mr-10 border cursor-pointer bg-shade lg:w-full lg:mr-0"
              :class="thumb == 2 ? 'border-primary' : 'border-white'"
              @click="thumb = 2"
              v-if="data.Photo_3.length"
            >
              <div class="flex items-center w-16 h-16 mx-auto">
                <img
                  :src="data.Photo_3"
                  style="max-height: 50px"
                  class="mx-auto"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="lg:w-1/2">
          <div
            class="container flex-col pl-0 pr-0 mx-auto mt-10 lg:mt-0 lg:pr-20"
          >
            <div class="hidden lg:block">
              <!-- <p class="text-primary font-lg">Best Value</p> -->
              <div class="flex justify-between text-xl font-bold">
                <p v-html="data.Name"></p>
                <p v-html="data.Price"></p>
              </div>
            </div>
            <p class="flex font-bold">
              <span>Our Rating:</span>
              <stars :rating="data.Rating" class="ml-2" />
            </p>
            <p class="mt-4" v-html="data.Description"></p>
            <!-- <div class="inline-block"> -->
            <red-button class="mt-8" :href="data.Affilate_URL"
              >Click to buy</red-button
            >
            <!-- </div> -->
            <p class="mt-4 text-sm">
              <b>Note:</b> The price listed on our site is as of {{data.updatedAt ? new Date(data.updatedAt.seconds * 1000).toLocaleDateString() : '9/15/2020' }}. All splints have been independently purchased and reviewed by ArmadilloMD. We earn from qualified purchases through Amazon.
            </p>
          </div>
        </div>
      </div>

      <div class="p-4 mt-8 bg-shade">
        <div class="container mx-auto">
          <h1 class="py-5 text-xl text-center mhead">Model Details:</h1>
          <div class="lg:flex">
            <div class="lg:w-1/2">
              <div class="flex flex-col">
                <div class="flex mb-4">
                  <img
                    src="../assets/Model Details/ConstructionQuality.svg"
                    class="w-8 h-8 mx-4 lg:-mt-2"
                  />
                  <div
                    class="leading-tight lg:flex lg:w-full lg:mr-20 lg:justify-between"
                  >
                    <p class="mr-3 font-bold">Construction quality</p>
                    <p v-html="data.Construction"></p>
                  </div>
                </div>
                <div class="flex mb-4">
                  <img
                    src="../assets/Model Details/EaseDonDoff.svg"
                    class="w-8 h-8 mx-4 lg:-mt-2"
                  />
                  <div
                    class="leading-tight lg:flex lg:w-full lg:mr-20 lg:justify-between"
                  >
                    <p class="mr-3 font-bold">Ease of don/doff</p>
                    <p v-html="data.Ease_don_doff"></p>
                  </div>
                </div>
                <div class="flex mb-4">
                  <img
                    src="../assets/Model Details/Comfort.svg"
                    class="w-8 h-8 mx-4 lg:-mt-2"
                  />
                  <div
                    class="leading-tight lg:flex lg:w-full lg:mr-20 lg:justify-between"
                  >
                    <p class="mr-3 font-bold">Comfort</p>
                    <p v-html="data.Comfort"></p>
                  </div>
                </div>
                <div class="flex mb-4">
                  <img
                    src="../assets/Model Details/Durability.svg"
                    class="w-8 h-8 mx-4 lg:-mt-2"
                  />
                  <div
                    class="leading-tight lg:flex lg:w-full lg:mr-20 lg:justify-between"
                  >
                    <p class="mr-3 font-bold">Durability</p>
                    <p v-html="data.Durability"></p>
                  </div>
                </div>
                <div class="flex mb-4">
                  <img
                    src="../assets/Model Details/Value.svg"
                    class="w-8 h-8 mx-4 lg:-mt-2"
                  />
                  <div
                    class="leading-tight lg:flex lg:w-full lg:mr-20 lg:justify-between"
                  >
                    <p class="mr-3 font-bold">Value</p>
                    <p v-html="data.Value"></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="lg:w-1/2">
              <div class="flex flex-col">
                <div class="flex mb-4">
                  <img
                    src="../assets/Model Details/Versatility.svg"
                    class="w-8 h-8 mx-4 lg:-mt-2"
                  />
                  <div
                    class="leading-tight lg:flex lg:w-full lg:mr-20 lg:justify-between"
                  >
                    <p class="mr-3 font-bold">Right/left versatility</p>
                    <p v-html="data.Versatility"></p>
                  </div>
                </div>
                <div class="flex mb-4">
                  <img
                    src="../assets/Model Details/Washability.svg"
                    class="w-8 h-8 mx-4 lg:-mt-2"
                  />
                  <div
                    class="leading-tight lg:flex lg:w-full lg:mr-20 lg:justify-between"
                  >
                    <p class="mr-3 font-bold">Washability</p>
                    <p v-html="data.Washability"></p>
                  </div>
                </div>
                <div class="flex mb-4">
                  <img
                    src="../assets/Model Details/StyleDesign.svg"
                    class="w-8 h-8 mx-4 lg:-mt-2"
                  />
                  <div
                    class="leading-tight lg:flex lg:w-full lg:mr-20 lg:justify-between"
                  >
                    <p class="mr-3 font-bold">Style/design</p>
                    <p v-html="data.Style"></p>
                  </div>
                </div>
                <div class="flex mb-4">
                  <img
                    src="../assets/Model Details/ColorsStyle.svg"
                    class="w-8 h-8 mx-4 lg:-mt-2"
                  />
                  <div
                    class="leading-tight lg:flex lg:w-full lg:mr-20 lg:justify-between"
                  >
                    <p class="mr-3 font-bold">Colors/styles</p>
                    <p v-html="data.Colors"></p>
                  </div>
                </div>
                <div class="flex mb-4">
                  <img
                    src="../assets/Model Details/Price.svg"
                    class="w-8 h-8 mx-4 lg:-mt-2"
                  />
                  <div
                    class="leading-tight lg:flex lg:w-full lg:mr-20 lg:justify-between"
                  >
                    <p class="mr-3 font-bold">Price</p>
                    <p v-html="data.Price"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer-md class="px-10 mt-4 text-gray-700 lg:px-0 lg:mt-0" />
  </div>
</template>

<script>
import Stars from "../components/Stars.vue";
import Footer from "../components/Footer.vue";
import { analytics } from "../firebase";
export default {
  name: "Details",
  components: {
    "footer-md": Footer,
    stars: Stars,
  },
  computed: {
    photo() {
      return this.purl();
    },
    multiplePics() {
      return !!this.data.Photo_2.length;
    },
  },
  methods: {
    purl() {
      switch (this.thumb) {
        case 0:
          return this.data.Photo_1;
        case 1:
          return this.data.Photo_2;
        case 2:
          return this.data.Photo_3;
      }
      return "";
    },
    picInValid() {
      var p = this.purl();
      return !p.length;
    },
    turnLeft() {
      do {
        if (--this.thumb < 0) this.thumb = 2;
      } while (this.picInValid());
    },
    turnRight() {
      do {
        if (++this.thumb > 2) this.thumb = 0;
      } while (this.picInValid());
    },
  },
  data() {
    return {
      thumb: 0,
      data: null,
    };
  },
  mounted() {
    this.data = this.$parent.cms.products[
      this.$parent.slug(this.$route.params.idx)
    ];
    if (!this.data) {
      this.$parent.alert("Product not found");
    } else {
      this.$nextTick(() => {
        analytics.logEvent("Detail:" + this.$route.params.idx);
      });
    }
  },
};
</script>

<style>
</style>
