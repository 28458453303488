<template>
  <div class="flex">
    <span v-for="idx in 5" :key="idx">
      <img v-if="rating < idx" src="../assets/GrayStar.svg" />
      <img v-else src="../assets/RedStar.svg" />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    rating: {
      default: "0"
    }
  }
};
</script>

<style>
img {
  margin-right: 5px;
}
</style>